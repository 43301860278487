import { useForm } from 'react-hook-form'
import { i18n } from '../i18n'

/**
 * @typedef {object} SearchBoxProps
 * @property {string=} defaultValue
 * @property {function(string):void} onSearch
 */

/**
 * @typedef {object} SearchValue
 * @property {string} search Search value
 */

/**
 * @typedef {import('react-hook-form').SubmitHandler<SearchValue>} SearchHandler
 */

/**
 * @param {SearchBoxProps} props
 */
function SearchBox({ defaultValue, onSearch }) {
    const { register, handleSubmit } = useForm()
    /** @type { SearchHandler } */
    let onSubmit = (values) => {
        if (values.search) {
            return onSearch(values.search)
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input className="text" type="search" defaultValue={defaultValue || ''}
                placeholder={i18n('label.search')} {...register('search')} />
            <input data-testid="submit" type="submit" hidden />
        </form>
    )
}

export default SearchBox
