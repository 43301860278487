import { Link, useLocation, useMatch, useSearchParams } from 'react-router'
import Icon from './components/Icon'
import { i18n } from './i18n'
import { Fragment, useEffect, useState } from 'react'
import { info } from './api'

export const Sidebar = () => {
  const { visitor, statsIRead, statsMessages, statsMyBL, statsMyReaders, statsReplies, tagStats } = window.state
  const match = useMatch('/:user/:mid')
  const [user, setUser] = useState(visitor)
  const location = useLocation()
  const [search] = useSearchParams()
  useEffect(() => {
    if (match) {
      info(match.params.user).then(response => {
        if (response.status === 200) {
          setUser(response.data)
        }
      }).catch(console.log)
    }
  }, [match])
  const discover = location.pathname === '/' && search?.get('show') == 'all'
  return (
    <>
      <div className="toolbar">
        {
          discover || !visitor?.uid ? (
            <>
              <Link to="/?show=top" title="Top" reloadDocument={true}>
                <Icon name="ei-heart" size="s"></Icon>
                <span className="desktop">Top</span>
              </Link>
              <Link to="/?show=all" title={i18n('link.allMessages')} reloadDocument={true}>
                <Icon name="ei-search" size="s"></Icon>
                <span className="desktop">{i18n('link.allMessages')}</span>
              </Link>
              <Link to="/?show=photos" title={i18n('link.withPhotos')} reloadDocument={true}>
                <Icon name="ei-camera" size="s"></Icon>
                <span className="desktop">{i18n('link.withPhotos')}</span>
              </Link>
            </>
          ) : (
            visitor && visitor.uid > 0 && visitor.uid != user.uid ? <UserMenu user={user} /> : <MyMenu />
          )
        }
      </div>
      <form action={`/${user.uname}/`} className="desktop" style={{ padding: '6px' }}>
        <p><input type="text" name="search" className="inp" placeholder={i18n('label.search')} /></p>
      </form>
      <span className="desktop" style={{ padding: '6px' }}>
        <div><UserTags uname={user.uname} tagList={tagStats} /></div>
      </span>
      <div id="ustats" className="desktop" style={{ padding: '6px' }}>
        <ul>
          <li><a href={`/${user.uname}/friends`}>{i18n('blog.iread')}: {statsIRead}</a></li>
          <li><a href={`/${user.uname}/readers`}>{i18n('blog.readers')}: {statsMyReaders}</a></li>
          {
            statsMyBL > 0 && visitor.uid == user.uid &&
            <li><a href={`/${user.uname}/bl`}>{i18n('blog.bl')}: {statsMyBL}</a></li>
          }
          <li>{i18n('blog.messages')}: {statsMessages}</li>
          <li>{i18n('blog.comments')}: {statsReplies}</li>
        </ul>
      </div>
    </>
  )
}

const UserMenu = ({ user }) => {
  const { visitor, isSubscribed, isInBL, isInWL, isInBLAny } = window.state
  return (
    <>
      <div id="ctitle">
        <a href={`/${user.uname}/`}>
          <img src={user.avatar} alt={user.uname} /><span>{user.uname}</span>
        </a>
      </div>
      {
        isSubscribed ? (
          <Link to={`/post?body=U+%40${user.uname}`} title="Подписан">
            <Icon name="ei-check" size="s" />
            <span className="desktop">Subscribed</span>
          </Link>
        ) : (
          <Link to={`/post?body=S+%40${user.uname}`} title="Подписаться">
            <Icon name="ei-plus" size="s" />
            <span className="desktop">Subscribe</span>
          </Link>
        )
      }
      {
        isInBL ? (
          <Link to={`/post?body=BL+%40${user.uname}`} title="Разблокировать">
            <Icon name="ei-close-o" size="s" />
            <span className="desktop">Unblock</span>
          </Link>
        ) : (
          <Link to={`/post?body=BL+%40${user.uname}`} title="Заблокировать">
            <Icon name="ei-close" size="s" />
            <span className="desktop">Block</span>
          </Link>
        )
      }
      {
        visitor.premium || visitor.admin &&
        (
          isInWL ? (
            <a href={`/api/users/wl?nick=${user.uname}`} data-name={user.uname} className="a-vip">
              <Icon name="ei-unlock" size="s" />
              <span className="desktop">Remove from VIP</span>
            </a>
          ) : (
            <a href={`/api/users/wl?nick=${user.uname}`} data-name={user.uname} className="a-vip">
              <Icon name="ei-lock" size="s" />
              <span className="desktop">Add to VIP</span>
            </a>
          )
        )
      }
      {
        !isInBLAny &&
        <a href={`/pm/sent?uname=${user.uname}`} title="Написать приватное сообщение">
          <Icon name="ei-envelope" size="s" />
          <span className="desktop">PM</span>
        </a>
      }
    </>
  )
}

const MyMenu = () => {
  const { visitor } = window.state
  return (
    <>
      <Link to="/?show=my"><Icon name="ei-clock" size="s" />
        <span className="desktop">{i18n('link.my')}</span>
      </Link>
      <a href="/pm/inbox"><Icon name="ei-envelope" size="s" />
        <span className="desktop">{i18n('link.privateMessages')}</span>
      </a>
      <Link to="/"><Icon name="ei-bell" size="s" />
        <span className="desktop">{i18n('link.discuss')}</span>
      </Link>
      <Link to="/settings" rel="nofollow">
        <Icon name="ei-gear" size="s" />
        <span className="desktop">{i18n('link.settings')}</span>
        {
          !visitor.verified && (
            <span style={{ color: 'red' }}><Icon name="ei-exclamation" size="s" /></span>
          )
        }
      </Link>
    </>
  )
}

const UserTags = ({ uname, tagList }) => {
  return (
    <>
      {
        tagList && tagList.map(tag => {
          return <Fragment key={tag}><a className="hashtag" href={`/${uname}/?tag=${tag}`}>{tag}</a>&nbsp;</Fragment>
        })
      }
      <a href={`/${uname}/tags`} rel="nofollow">...</a>
    </>
  )
}
