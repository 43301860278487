import Icon from './components/Icon'
import { i18n, timeAgo } from './i18n'

import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { comment, thread, update } from './api'
import { Link, useLocation, useParams } from 'react-router'
import MessageInput from './components/MessageInput'
import ScrollInto from 'react-scroll-into-view'

import axios from 'axios'
import { embedUrls, format } from './utils/embed'
import { UserLink } from './components/UserInfo'
import defaultAvatar from '../assets/av-96.png'


/**
 * @type { import('./api').Message }
 */
const emptyMessage = {}

const isTheSame = (old, msg) => old.mid == msg.mid && old.rid == msg.rid
const isOwn = (visitor, msg) => msg.user.uid == visitor.uid

const displayTags = (tags = []) => tags.map(t => (`*${t}`)).join(',')

export const Thread = () => {
  const { visitor, retpath } = window.state || {}
  const params = useParams()
  const location = useLocation()
  const initialMessage = location.state?.data && [location.state.data]
  const [messages, setMessages] = useState(/** @type { Array<import('./api').Message>= } */ (initialMessage))
  const [editing, setEditing] = useState(emptyMessage)

  let updateThread = useCallback((msg) => {
    try {
      var jsonMsg = JSON.parse(msg.data)
      console.log('data: ' + msg.data)
      if (jsonMsg.service || jsonMsg.mid != params.mid) {
        return
      }
      setMessages((prev) => {
        const updated = prev.map((old) => {
          if (isTheSame(old, msg)) {
            return msg
          }
          return old
        })
        if (updated.find(old => isTheSame(old, msg))) {
          return updated
        }
        return [...prev, { ...jsonMsg, isNew: !isOwn(visitor, jsonMsg) }]
      })
    } catch (err) {
      console.log(err)
    }
  }, [params.mid, visitor])

  useEffect(() => {
    let es
    let cleanup = () => {
      if (es) {
        es.close()
      }
    }
    if (visitor) {
      if ('EventSource' in window) {
        es = new EventSource('/api/events')
        es.onopen = () => {
          console.log('online')
        }
        es.onerror = () => {
          es.removeEventListener('msg', updateThread)
        }
        es.addEventListener('msg', updateThread)
        window.addEventListener('beforeunload', cleanup)
      }
    }
    return (() => {
      if (es && es.removeEventListener) {
        es.removeEventListener('msg', updateThread)
      }
      cleanup()
    })
  }, [params.mid, updateThread, visitor])

  useEffect(() => {
    thread(params.mid).then(response => {
      if (response.status === 200) {
        setMessages(response.data)
      }
    }).catch(console.log)
  }, [params.mid])
  let postComment = async ({ to, body, attach }) => {
    try {
      let res = editing.rid ? await update(params.mid, editing.rid.toString(), body)
        : await comment(params.mid, to, body, attach)
      if (res.status == 200 && res.data.newMessage) {
        setEditing(emptyMessage)
        updateThread(res.data.newMessage)
      }
      return res.data
    } catch (e) {
      console.error(e)
    }
    return false
  }
  const msg = messages && messages.slice(0, 1).shift()
  const markRead = (reply) => {
    if (!reply.isNew) return
    const url = `/api/thread/mark_read/${reply.mid}-${reply.rid || 0}.gif`
    axios.get(url).then((response) => {
      if (response.status === 200) {
        setMessages((prev) => {
          return prev.map(m => {
            if (m.rid === reply.rid) {
              return { ...m, isNew: false }
            }
            return m
          })
        })
      }
    }).catch(console.log)
  }
  const replies = messages && messages.slice(1)
  const unread = replies && replies.filter(r => r.isNew) || []
  const isCode = (msg && msg.tags || []).indexOf('code') >= 0
  /**
   * @type {import('react').MutableRefObject<HTMLDivElement?>}
   */
  const embedRef = useRef(null)
  /**
   * @type {import('react').MutableRefObject<HTMLDivElement?>}
   */
  const msgRef = useRef(null)
  useEffect(() => {    
    const msg = msgRef.current
    const embed = embedRef.current
    if (msg && embed) {
      embedUrls(msg.querySelectorAll('a'), embed, false)
      if (!embed.hasChildNodes()) {
        embed.style.display = 'none'
      }
    }
  }, [msg])
  useEffect(() => {
    document.title = msg && (msg.tags ? `${msg.user.uname} : ${displayTags(msg.tags)}` : msg.user.uname)
  }, [msg])
  const created_at = msg && new Date(`${msg.timestamp}Z`)
  const isNSFW = msg?.tags?.includes('NSFW')
  return (
    <>
      <ul id="0">
        {
          msg ? (
            <li id={`msg-${msg.mid}`} className='msg msgthread'>
              <article className={`msg-cont ${isNSFW ? 'nsfw' : ''}`}>
                <div className="msg-header">
                  <div className="msg-avatar">
                    <a href={`/${msg.user.uname}/`}>
                      <img src={msg.user.avatar} alt={msg.user.uname} />
                    </a>
                  </div>
                  <span>
                    <a href={`/${msg.user.uname}/`}>
                      <span>{msg.user.uname}</span>
                      {msg.user.premium && (
                        <span style={{ color: 'green' }}>
                          <Icon name="ei-star" size="s" />
                        </span>
                      )}
                    </a>
                  </span>
                  <div className="msg-ts">
                    {msg.friendsOnly && (
                      <>
                        <Icon name="ei-lock" size="s" /> &middot;
                      </>
                    )}
                    <a href={`/${msg.user.uname}/${msg.mid}`}>
                      <time
                        dateTime={created_at}
                        title={created_at}>
                        {timeAgo(created_at)}
                      </time>
                    </a>
                    {msg.updated_at !== msg.timestamp && <> &middot; Edited</>}
                    {
                      visitor && visitor.uid == msg.user.uid &&
                      <>
                        <span>&nbsp;&middot;&nbsp;</span>
                        <Link to={{
                          pathname: '/post',
                        }} state={{ data: msg }}>Edit</Link>
                      </>
                    }
                  </div>
                </div>
                {
                  msg.user && msg.mid &&
                  <div className="msg-txt" ref={msgRef}>
                    <Tags user={msg.user} data={msg.tags} />
                    {msg.body && <MessageContainer isCode={isCode} data={{ __html: format(msg.body, msg.mid.toString(), isCode) }} />}
                  </div>
                }
                {
                  msg.photo && msg.attachment && msg.attachment.small &&
                  <div className="msg-media">
                    <a href={`/i/p/${msg.mid}.${msg.attach}`} data-fname={`${msg.mid}.${msg.attach}`}>
                      <img src={msg.attachment.small.url} alt="Message media" />
                    </a>
                  </div>
                }
                <div className="embedContainer" ref={embedRef} />
                <nav className="l">
                  {visitor && visitor.uid === msg.user.uid && (msg.user.premium || msg.user.admin) ? (
                    <a href={`/${msg.mid}`} className="a-privacy msg-button">
                      <span className="msg-button-icon">
                        {msg.friendsOnly ? (
                          <>
                            <Icon name="ei-unlock" size="s" />
                            <span>&nbsp;{i18n('message.unlock')}</span>
                          </>
                        ) : (
                          <>
                            <Icon name="ei-lock" size="s" />
                            <span>&nbsp;{i18n('message.lock')}</span>
                          </>
                        )}
                      </span>
                    </a>
                  ) : visitor && visitor.uid > 0 ? (
                    <a href={`/post?body=!+%23${msg.mid}`} className="a-like msg-button">
                      <span className="msg-button-icon">
                        <Icon name="ei-heart" size="s" />
                        {msg.recommendations?.length > 0 ? (
                          <>&nbsp;{msg.recommendations.length}</>
                        ) : (
                          <span>&nbsp;{i18n('message.recommend')}</span>
                        )}
                      </span>
                    </a>
                  ) : (
                    <a href={`/login?retpath=${retpath || '/'}`} className="a-login msg-button">
                      <span className="msg-button-icon">
                        <Icon name="ei-heart" size="s" />
                        {msg.recommendations?.length > 0 ? (
                          <>&nbsp;{msg.recommendations.length}</>
                        ) : (
                          <span>&nbsp;{i18n('message.recommend')}</span>
                        )}
                      </span>
                    </a>
                  )}
                  {visitor && visitor.uid > 0 && (
                    visitor.uid !== msg.user.uid ? (
                      msg.subscribed ? (
                        <a href={`/post?body=U+%23${msg.mid}`} className="msg-button">
                          <span className="msg-button-icon">
                            <Icon name="ei-check" size="s" />
                            <span>&nbsp;{i18n('message.subscribed')}</span>
                          </span>
                        </a>
                      ) : (
                        <a href={`/post?body=S+%23${msg.mid}`} className="a-sub msg-button">
                          <span className="msg-button-icon">
                            <Icon name="ei-eye" size="s" />
                            <span>&nbsp;{i18n('message.subscribe')}</span>
                          </span>
                        </a>
                      )
                    ) : (
                      <a href={`/post?body=D+%23${msg.mid}`} className="msg-button">
                        <span className="msg-button-icon">
                          <Icon name="ei-close" size="s" />
                          <span>&nbsp;{i18n('message.delete')}</span>
                        </span>
                      </a>
                    )
                  )}
                </nav>
                {
                  canComment(msg, visitor) && (
                    <MessageInput text={editing.body || ''} onSend={(props) => postComment({ ...props, to: 0 })} onSuccess={() => { }} placeholder={i18n('message.writeComment')} />
                  )
                }
                {msg.recommendations?.length > 0 && (
                  <div className="msg-recomms">
                    {i18n('message.recommendedBy')}&nbsp;
                    {msg.recommendations.map((rec, index) => (
                      <span key={rec.uname}>
                        {!rec.uri ? (
                          <a href={`/${rec.uname}/`}>@{rec.uname}</a>
                        ) : (
                          <a href={rec.uri}>
                            @{rec.uname}<span className="dimmed">@{new URL(rec.uri).host}</span>
                          </a>
                        )}
                        {index < msg.recommendations.length - 1 && ', '}
                      </span>
                    ))}
                  </div>
                )}
              </article>
            </li>
          ) : (<div className="loading"></div>)
        }
      </ul>

      <ul id="replies">
        {
          replies && replies.map(reply => (
            <li key={reply.rid} id={reply.rid.toString()} className={`msg ${reply.isNew ? 'reply-new' : ''}`}
              onMouseOver={() => markRead(reply)} onFocus={() => markRead(reply)}>
              <Comment reply={reply} onSend={(props) => postComment({ ...props, to: reply.rid })} />
            </li>
          ))
        }
      </ul>
      {unread && unread.length > 0 ? <ScrollInto selector='#replies>li.reply-new'><div id="wsthread">{unread.length}</div></ScrollInto> : null}
    </>
  )
}

/**
 * @param {{isCode: boolean, data: {__html: string}}} props props
 */
function MessageContainer({ isCode, data }) {
  return isCode ? (<pre dangerouslySetInnerHTML={data} />) : (<span dangerouslySetInnerHTML={data} />)
}

/**
 * Tags component
 * @param {{user: import('../js/api').User, data: string[]}} props props
 */
export const Tags = ({ data, user }) => {
  return data && data.length > 0 ? (
    <span className="msg-tags">
      {
        data.map((tag) => (
          <Fragment key={tag}>
            <Link key={tag} className='hashtag' to={`/${user.uname}/?tag=${tag}`} title={tag} reloadDocument={true}>
              {tag}
            </Link>
            {' '}
          </Fragment>
        ))
      }
    </span>
  ) : null
}

const canComment = (msg, visitor) =>
  msg && msg.user && visitor && visitor.uid === msg.user.uid
  || msg && !msg.ReadOnly && visitor && visitor.uid > 0

const Comment = ({ reply, onSend }) => {
  const { visitor } = window.state || {}
  const [active, setActive] = useState(0)
  /**
   * @type {import('react').MutableRefObject<HTMLDivElement?>}
   */
  const embedRef = useRef(null)
  /**
   * @type {import('react').MutableRefObject<HTMLDivElement?>}
   */
  const replyRef = useRef(null)
  useEffect(() => {
    const msg = replyRef.current
    const embed = embedRef.current
    if (msg && embed) {
      embedUrls(msg.querySelectorAll('a'), embed, false)
      if (!embed.hasChildNodes()) {
        embed.style.display = 'none'
      }
    }
  }, [reply])
  const created_at = reply && new Date(`${reply.timestamp}Z`)
  return (
    <div className="msg-cont">
      <div className="msg-header" data-uri={reply.user.uri}>
        {!reply.user.banned ? (
          <UserLink key={reply.user.uri || reply.user.uid} user={reply.user} />
        ) : (
          <>
            [удалено]:
            <div className="msg-avatar">
              <img src={defaultAvatar} alt='Deleted user' />
            </div>
          </>
        )}
        <div className="msg-ts">
          <a href={`/${reply.mid}#${reply.rid}`}>
            <time
              dateTime={created_at}
              title={created_at}>
              {timeAgo(created_at)}
            </time>
          </a>
          {reply.updated_at !== reply.timestamp && <> &middot; Edited</>}
          {
            visitor && visitor.uid == reply.user.uid &&
            <>
              <span>&nbsp;&middot;&nbsp;</span>
              <Link to={'/post'} state={{ data: reply }}>Edit</Link>
            </>
          }
        </div>
      </div>
      {
        reply.body && reply.user && reply.mid &&
        <div className="msg-txt" ref={replyRef}>
          {reply.replyto && reply.replyto > 0 && (<span className="h-card"><UserLink key={reply.user.uri || reply.user.uid} user={reply.to} size="small" />,&nbsp;</span>)}
          <MessageContainer isCode={false} data={{ __html: format(reply.body, reply.mid.toString(), false, false) }} />
        </div>
      }
      {
        reply.photo && reply.attachment && reply.attachment.small &&
        <div className="msg-media">
          <a href={reply.attachment.url}>
            <img src={reply.attachment.small.url} alt="Message media" />
          </a>
        </div>
      }
      <div className="embedContainer" ref={embedRef} />
      {
        active === reply.rid && <MessageInput text={''} onSend={onSend} onSuccess={() => setActive()} placeholder={i18n('message.writeComment')} />
      }
      <div className="msg-links">
        /{reply.rid}
        {reply.replyto > 0 && (
          <>
            &nbsp;{i18n('reply.inReplyTo')} <a href={`#${reply.replyto}`}>/{reply.replyto}</a>
          </>
        )}
        {canComment(reply, visitor) ? (
          <>
            &nbsp;&middot; <a href={`/post?body=%23${reply.mid}/${reply.rid}%20`} onClick={(e) => {
              e.preventDefault()
              setActive(reply.rid)
            }}>
              {i18n('reply.reply')}
            </a>
            <div className="msg-comment-target msg-comment-hidden"></div>
          </>
        ) : visitor && !visitor.uid ? (
          <>
            &middot; <a href={`/login?retpath=${window.location.href}`} className="a-login">{i18n('reply.reply')}</a>
          </>
        ) : null}
      </div>
    </div>
  )
}
